<template>
  <div>
    <b-card>
      <template #header>
        <div style="display: contents !important">
          <h5>{{ notificationDto.title }}</h5>
          <p>
            {{ formDate(notificationDto.date) }}
            <b-button
              variant="transparnt"
              class="p-0"
              @click="deleteNotifications(notificationDto.id)"
            >
              <unicon fill="gray" height="20px" name="trash-alt"></unicon>
            </b-button>
          </p>
        </div>
      </template>
      <b-card-text>{{ notificationDto.body }}</b-card-text>
      <b-card-footer>
        <div style="display: flex; justify-content: space-between">
          <b-button variant="outline-primary" to="/admin/notifications"
            >تراجع</b-button
          >
          <div>
            المرسل إليه:
            <b-badge
              pill
              variant="light-primary"
              class="mr-1"
              v-if="notificationDto.sendTo == 1"
              >تطبيق الطلاب</b-badge
            >
            <b-badge
              pill
              class="mr-1"
              variant="light-warning"
              v-if="notificationDto.sendTo == 2"
              >تطبيق نقاط البيع</b-badge
            >
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
  props: {
    id: String,
  },
  data: () => ({
    nullGuid,
  }),
  computed: {
    ...mapState({
      notificationDto: (state) => state.notifications.notificationDto,
    }),
  },
  methods: {
    ...mapActions(["getNotificationDetails", "deleteNotifications"]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    getName(num) {
      if (num == 1) return "تطبيق الطالب";
      else if (num == 2) return "تطبيق نقطة البيع";
    },
  },
  created() {
    this.getNotificationDetails(this.id);
  },
};
</script>